ion-fab-button.tag {
    align-items: start;

}
ion-fab-list.tags {
    align-items: start;
}

ion-badge.tag-title {
    position: relative;
    left: 2.8rem;
    bottom: -2.2rem;
}