ion-card.card-details {
    box-shadow: none !important;
    border: none !important

}

div.div-color {
    background-color:  #3c4d7a!important;

}
